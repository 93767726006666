import { createSlice } from '@reduxjs/toolkit'
import { demandeApiSlice } from './demandeApiSlice';

const initialState = {
  demandes: [],
}

export const demandeSlice = createSlice({
  name: 'demande',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      demandeApiSlice.endpoints.getDemandes.matchFulfilled,
      (state, { payload }) => {
        state.demandes = payload;
      }
    )
    .addMatcher(
      demandeApiSlice.endpoints.updateVehiculeState.matchFulfilled,
      (state, { payload }) => {
        state.demandes = payload;
      }
    )
  },
})

// Action creators are generated for each case reducer function
export const { } = demandeSlice.actions;

export default demandeSlice.reducer;