import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';
import { baseQueryWithReauth } from './customBaseQuery';

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  reducerPath: 'api-slice',
  refetchOnMountOrArgChange: true,
  tagTypes: ["users", "proprietaire", "demandes", "locations-progress", "locations-comming", "locations-waiting", "locations-ended", "locations-commissions", "demandes-vehicules", "rooms", "chats", "user-profil", "vehicules"],
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
})