import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div className="dhiwise-navigation">
      <h1>Homepage</h1>
      <p className="headline">
        This project was generated By{" "}
        <a href="https://www.dhiwise.com">Dhiwise</a>. Quickly use below links
        to navigate through all pages.
      </p>
      <ul>
        <li>
          <Link to="/">Connexion</Link>
        </li>
        <li>
          <Link to="/dashboard">Dashboard</Link>
        </li>
        <li>
          <Link to="/demandes">Demandes</Link>
        </li>
        <li>
          <Link to="/messages">Messages</Link>
        </li>
        <li>
          <Link to="/locations">Locations</Link>
        </li>
        <li>
          <Link to="/utilisateurs">Utilisateurs</Link>
        </li>
        <li>
          <Link to="/propritaires">Propritaires</Link>
        </li>
        <li>
          <Link to="/propritairesdemandes">Propritairesdemandes</Link>
        </li>
        <li>
          <Link to="/statistiques">Statistiques</Link>
        </li>
        <li>
          <Link to="/notification">Notification</Link>
        </li>
      </ul>
    </div>
  );
};
export default Home;
