import { createSlice } from '@reduxjs/toolkit'
import { userApiSlice } from './userApiSlice';

const initialState = {
  users: [],
  proprietaires: [],
  proprietairesDemandes: []
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      userApiSlice.endpoints.getUsers.matchFulfilled,
      (state, { payload }) => {
        state.users = payload;
      }
    )
    .addMatcher(
      userApiSlice.endpoints.getProprietaires.matchFulfilled,
      (state, { payload }) => {
        state.proprietaires = payload;
      }
    )
    .addMatcher(
      userApiSlice.endpoints.getProprietairesDemandes.matchFulfilled,
      (state, { payload }) => {
        state.proprietairesDemandes = payload;
      }
    )
    .addMatcher(
      userApiSlice.endpoints.updateDemandeState.matchFulfilled,
      (state, { payload }) => {
        state.proprietairesDemandes = payload;
      }
    )
  },
})

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions;

export default userSlice.reducer;