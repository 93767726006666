import { createSlice } from '@reduxjs/toolkit'
import { authApiSlice } from './authApiSlice';

const initialState = {
  user: null,
  token: null,
  favoris: [],
  isAuthenticated: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { user, token }) => {
      state.isAuthenticated = true;
      state.user = user;
      state.token = token;
    },
    logout: (state) => {
      localStorage.removeItem("token");
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      authApiSlice.endpoints.login.matchFulfilled,
      (state, { payload: { user, token } }) => {
        updateAuthState(state, { user, token });
      }
    )
    builder.addMatcher(
      authApiSlice.endpoints.getProfile.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        state.isAuthenticated = true;
      }
    )
  },
})

const updateAuthState = (
  state,
  {
    user,
    token
  }
) => {
  state.user = user;
  state.token = token;
  state.isAuthenticated = true;
  localStorage.setItem("token", token);
};


// Action creators are generated for each case reducer function
export const { login, logout } = authSlice.actions;

export default authSlice.reducer;