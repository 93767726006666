import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import config from "config";


const baseQuery = fetchBaseQuery({
  baseUrl: config.apiURL,
  prepareHeaders: async (headers) => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken) {
      headers.set("Authorization", `Bearer ${jwtToken}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  return result;
};
