import { createSlice } from '@reduxjs/toolkit'
import { messageApiSlice } from './messageApiSlice';

const initialState = {
  rooms: [],
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      messageApiSlice.endpoints.getRooms.matchFulfilled,
      (state, { payload }) => {
        state.rooms = payload;
      }
    )
  },
})

// Action creators are generated for each case reducer function
export const { } = messageSlice.actions;

export default messageSlice.reducer;