import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import { useDispatch, useSelector } from "react-redux";
const Notification = React.lazy(() => import("pages/Notification"));
const Statistiques = React.lazy(() => import("pages/Statistiques"));
const Propritairesdemandes = React.lazy(
  () => import("pages/Propritairesdemandes"),
);
const Propritaires = React.lazy(() => import("pages/Propritaires"));
const Utilisateurs = React.lazy(() => import("pages/Utilisateurs"));
const Locations = React.lazy(() => import("pages/Locations"));
const Messages = React.lazy(() => import("pages/Messages"));
const Demandes = React.lazy(() => import("pages/Demandes"));
const Dashboard = React.lazy(() => import("pages/Dashboard"));
const Connexion = React.lazy(() => import("pages/Connexion"));

const ProjectRoutes = () => {

  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      if (window.location.pathname !== "/login") {
        window.location.href = "/login"
        //console.log("other page")
      }
    }
  }, [dispatch, user, isAuthenticated]);

  return (
    <React.Suspense fallback={<></>}>
      <Router>
        <Routes>
          <Route path="/login" element={<Connexion />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/demandes" element={<Demandes />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/utilisateurs" element={<Utilisateurs />} />
          <Route path="/propritaires" element={<Propritaires />} />
          <Route
            path="/propritairesdemandes"
            element={<Propritairesdemandes />}
          />
          <Route path="/statistiques" element={<Statistiques />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/dhiwise-dashboard" element={<Home />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
