import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  vehicules: [],
}

export const vehiculeSlice = createSlice({
  name: 'vehicule',
  initialState,
  reducers: {},
  extraReducers(builder) {
    /*
    builder.addMatcher(
      locationrApiSlice.endpoints.getUsers.matchFulfilled,
      (state, { payload }) => {
        state.locations = payload;
      }
    )
    */
  },
})

// Action creators are generated for each case reducer function
export const { } = vehiculeSlice.actions;

export default vehiculeSlice.reducer;