import { ProSidebarProvider } from "react-pro-sidebar";
import React, { useEffect, useState } from "react";
import Routes from "./Routes";
import { useSelector } from "react-redux";
import { useLazyGetProfileQuery } from "features/auth/authApiSlice";

function App() {
  const { user } = useSelector((state) => state.auth);
  const [isLoaded, setIsLoaded] = useState(false);
  const [getProfile, { isError, isSuccess }] = useLazyGetProfileQuery();

  useEffect(() => {
    if (user) {
      setIsLoaded(true);
      return;
    }

    if (!user) {
      const refreshToken = localStorage.getItem('token')
      if (refreshToken) {
        getProfile();
      } else {
        setIsLoaded(true);
        console.log("login");
        if (window.location.pathname !== "/login") {
          window.location.href = "/login"
        }
      }

    }
  }, [user, getProfile]);

  useEffect(() => {
    if (isError || isSuccess) {
      setIsLoaded(true);
    }
  }, [isError, isSuccess]);

  if (!isLoaded) {
    return <></>;
  }

  return (
    <ProSidebarProvider>
      <Routes />
    </ProSidebarProvider>
  );
}

export default App;
