import { createSlice } from '@reduxjs/toolkit'
import { locationrApiSlice } from './locationApiSlice';

const initialState = {
  locations: [],
}

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers(builder) {
    /*
    builder.addMatcher(
      locationrApiSlice.endpoints.getUsers.matchFulfilled,
      (state, { payload }) => {
        state.locations = payload;
      }
    )
    */
  },
})

// Action creators are generated for each case reducer function
export const { } = locationSlice.actions;

export default locationSlice.reducer;