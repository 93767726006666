import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import userReducer from '../features/user/userSlice';
import locationReducer from '../features/location/locationSlice';
import demandeReducer from '../features/demande/demandeSlice';
import messageReducer from '../features/message/messageSlice';
import vehiculeReducer from '../features/vehicules/vehiculeSlice';
import { apiSlice } from './apiSlice';

export const store = configureStore({
    reducer: {
        // Add the generated reducer as a specific top-level slice
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        user: userReducer,
        location: locationReducer,
        demande: demandeReducer,
        message: messageReducer,
        vehicule: vehiculeReducer,
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(apiSlice.middleware);
    },
})